.container {
    *[class*="mantine-AppShell-root"] {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
    }

    *[class*="mantine-AppShell-body"] {
        flex-grow: 1;
    }

    *[role="tabpanel"] {
        padding: 1rem;
        min-height: 60vh;
    }

    *[role="tab"] {
        width: 15rem;
    }

    h1 {
        font-size: 1rem;
        cursor: pointer;
    }
}