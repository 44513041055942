.container {
    background: rgba(255, 255, 255, 0.1);
    // width: 25%;
    flex-grow: 1;
    flex-shrink: 0;
    padding: 1rem;
    border-left: thin solid rgba(255, 255, 255, 0.2);

    display: flex;
    flex-direction: column;
}