.container {
    // background: rgba(0, 0, 0, 0.1);
    width: 30rem;
    // border-radius: 0.25rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
}

.header {
    width: 100%;
    height: 4rem;
    background: var(--background3);
    background-size: contain;
    position: relative;

    border-top: thin solid var(--borderColor);
    border-bottom: thin solid var(--borderColor);
}

.title {
    // background: #ccc;
    // padding: 0.5rem;
    // text-align: center;
    position: absolute;
    bottom: 0;
    left: 0;
    max-width: 80%;
    background: rgba(255, 255, 255, 0.7);
    height: 1.5rem;
    backdrop-filter: blur(3px);
    color: black;
    font-family: "Open Sans", sans-serif;   
    display: flex;
    align-items: center;
    font-weight: bold;

    h3 {
        margin-left: 0.5rem;
    }

    &, * {
        font-size: 0.8rem;
        line-height: 1;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}

.body {
    // min-height: 20rem;
    position: relative;
    // background: linear-gradient(180deg,#eaf0f6,#d8e4ee);
    flex-grow: 1;
    transition: height 0.2s ease-in;

    // button {
        // opacity: 0.8;
    // }

    &>* {
        &::before {
            position: absolute;
            left: -100vw;
            bottom: -10px;
            width: 300vw;
            border-bottom: thin solid currentColor;
            height: 0;
            opacity: 0.1;
            overflow: hidden;
            content: " ";
        }
    }
}

