.container {
    display: flex;
    flex-direction: column;
    // background: rgba(0, 0, 0, 0.03);
    padding: 0.5rem;
    width: 25%;
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;

    button {
        padding: 0.618rem;
        height: auto;
        background: transparent;
        border-color: transparent;

        &.selected {
            border-color: rgba(0, 0, 0, 0.3);
        }

        div {
            height: auto;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        span {
            margin: 0;
        }

        span + span {
            margin-top: 0.5rem;
            max-width: 5em;
            white-space: normal;
            line-height: 1.3;
            // overflow: hidden;
        }
    }

    .button {
        border: none;
        border-radius: 0.25rem;
        background: transparent;
        padding: 0.5rem;
        margin-top: 0.618rem;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        strong {
            margin-top: 0.5rem;
            font-weight: bold;
            max-width: 5em;
            display: block;
        }

        &:hover {
            background: #eee;
        }

        &:active {
            background: #ccf;
        }
    }
}