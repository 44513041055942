.container {
    width: 100%;
    min-width: 0;

    .header {
        border-bottom: 0.2rem solid #000000;
        margin-bottom: 1.618rem;
        padding-bottom: 1.418rem;

        display: flex;
        align-items: flex-end;
        gap: 1rem;

        h2 {
            font-size: 1.618rem;
            font-weight: bold;
            flex-grow: 1;
        }

        button {
            line-height: 1;

            svg {
                width: 0.8rem;
                height: auto;
            }
        }
    }

    .thumbnail {
        aspect-ratio: 16 / 9;
        background: var(--shade-color-1);

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
}

.ellipsize {
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}