.container {
    display: flex;
    padding: 0 1rem;
    gap: 1rem;
}

.wrapper {
    display: flex;
    flex-grow: 1;
    align-items: center;
    height: 100%;
    gap: 1rem;
}

.title {
    font-family: "Open Sans";
    font-size: 1rem;
    color: currentColor;
    display: flex;
    align-items: center;
}

.actionButtons {
    display: flex;
    gap: 1rem;

    button {
    }

    a {
        margin: 0 1rem;
    }
}