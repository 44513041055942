.container {
    height: 100vh;
    display: flex;
    flex-direction: column;

    --fade-color: #f3f3f3;

    *[class*="mantine-AppShell-body"], *[class*="mantine-AppShell-main"] {
        min-height: 0;
        flex-grow: 1;
        background: transparent;
    }  

    *[class*="mantine-AppShell-body"] {
        &>* {
            min-height: 0;
            max-height: 100%;
            height: 100%;
            overflow-y: scroll;
        }
    }

    &.blended {
        *[class*="mantine-Header-root"] {
            border: none !important;
        }
    }
}

.sidebarInner {
    min-height: calc(100vh - 5rem - 5.2rem);
}

// .userButton {
//     display: flex;
//     flex-wrap: nowrap;
//     padding: 0.5rem;
//     height: auto;
//     margin: 0 -0.5rem;
//     border-radius: 0.25rem;
//     overflow: hidden;

//     &:hover {
//         background: var(--fade-color);
//     }

//     &>div {
//         padding: 0;
//         margin: 0;
//         width: 100%;
//     }

//     *[class*="mantine-Button-label"] {
//         &, &>* {
//             flex-grow: 1;
//             width: 100%;
//         }
//     }

//     * {
//         min-width: 0;
//         max-width: 100%;
//         flex-wrap: nowrap;
//         overflow: hidden;
//         text-overflow: ellipsis;
//     }
// }

// .sidebarNav {
//     width: 100%;

//     a {
//         display: block;
//         width: 100%;
//         padding: 0.5rem;
//         border-radius: 0.25rem;
//         // margin: 0 -0.5rem;

//         &:hover {
//             background: var(--fade-color);
//         }
//     }
// }

.light {
    $background: #ffffff;
    $foreground: #000000;

    --background: #{$background};
    --foreground: #{$foreground};
    
    --intensity-color: #eeeeee;

    --shade-color-1: #{rgba($foreground, 0.1)};
    --shade-color-2: #{rgba($foreground, 0.2)};
    --shade-color-3: #{rgba($foreground, 0.3)};

    --fade-color: #f3f3f3;
    --active-color: linear-gradient(180deg,#eaf0f6,#d8e4ee);
    --border-color: #{rgba($foreground, 0.1)};
}

.dark {
    $background: #222222;
    $foreground: #ffffff;

    --background: #{$background};
    --foreground: #{$foreground};
    
    --shade-color-1: #191919;
    --shade-color-2: #{rgba($foreground, 0.2)};
    --shade-color-3: #{rgba($foreground, 0.3)};

    --intensity-color: #000000;

    --fade-color: var(--shade-color-1);
    --active-color: var(--intensity-color);
    --border-color: #{rgba($foreground, 0.1)};
}

* {
    transition: all 0.1s ease-in;
}

.v2 {
    height: 100vh;
    display: grid; 
    gap: 0px 0px;
    background: var(--background);
    color: var(--foreground);

    &.standard {
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-rows: 4rem 1fr;
        grid-template-areas: 
            "Header Header Header Header"
            "Sidebar Main Main Main";
        
        .logo { display: none; }
        .header { 
            grid-area: Header;
            border-bottom: thin solid var(--border-color);
        }
        .sidebar { 
            grid-area: Sidebar;
            border-right: thin solid var(--border-color);
        }
        .main { 
            grid-area: Main; 
        }
    }

    &.noSidebar {
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-rows: 4rem 1fr;
        grid-template-areas: 
            "Header Header Header Header"
            "Main Main Main Main";
        
        .logo { display: none; }
        .header { 
            grid-area: Header;
            border-bottom: thin solid var(--border-color);
        }
        .sidebar { 
            display: none;
        }
        .main { 
            grid-area: Main; 
        }
    }

    &.withSidebar {
        grid-template-columns: 1fr 1fr 1fr 1fr; 
        grid-template-rows: 4rem 1fr; 
        gap: 0px 0px; 
        grid-template-areas: 
            "Logo Header Header Header"
            "Sidebar Main Main Main";

        .logo {
            grid-area: Logo;
            border-right: thin solid var(--border-color);
        }
        .header { 
            grid-area: Header;
            // border-bottom: thin solid var(--fade-color);
        }
        .sidebar { 
            grid-area: Sidebar;
            border-right: thin solid var(--border-color);
        }
        .main { 
            grid-area: Main; 
        }
    }

    &>* {
        min-height: 0;
        // background: var(--background);
        // color: var(--foreground);
    }

    .sidebar, .main {
        overflow-y: scroll;
        display: flex;
        flex-direction: column;
    }

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        h1 {
            font-family: Montserrat, sans-serif;
            font-size: 1.618rem;
        
            span {
                color: #3e7aae;
                font-weight: bold;
            }
        
            strong {
                color: darken(#3e7aae, 10%);
                font-weight: 800;
            }
        }
    }

    // .logo {
    //     background: #ccc;
    // }

    // .header {
    //     background: #eee;
    // }

    // .sidebar {
    //     background: #ddd;
    // }

    // .main {
    //     background: #fff;
    // }
}