.container {
    padding: calc(1.618rem * 1.618 * 1.618);

    section + section {
        margin-top: 2.618rem;
    }

    .header {
        border-bottom: 0.2rem solid #000000;
        margin-bottom: 1.618rem;
        padding-bottom: 1.418rem;

        display: flex;
        align-items: flex-end;
        gap: 1rem;

        h2 {
            font-size: 1.618rem;
            font-weight: bold;
            flex-grow: 1;
        }

        h3 {
            font-size: 1.2rem;
            font-weight: bold;
            flex-grow: 1;
        }

        button {
            line-height: 1;

            svg {
                width: 0.8rem;
                height: auto;
            }
        }
    }
}