.timeline {
    // min-height: 10rem;
    // padding: 0 1rem;
    // position: fixed;
    // bottom: 0;
    // left: 0;
    width: 100vw;
    position: relative;
    
    --background1: #fff;
    --background2: #eee;
    --background3: #aaa;
    --borderColor: #aaa;
    --shadowColor: rgba(0, 0, 0, 0.3);
    --invert1: #111;
    color: black;
    // --shadowColor: rgba(0, 0, 0, 0.4);

    &[data-theme="dark"] {
        --background1: transparent;
        --background2: #0a0a0a;
        --background3: #111;
        --borderColor: #222;
        --shadowColor: rgba(255, 255, 255, 0.1);
        --invert1: #eee;
        color: white;
    }

    border-top: thin solid var(--borderColor);

    .scenes {
        display: flex;
        overflow-x: scroll;

        li {
            display: flex;
            flex-direction: column;
            box-shadow: 0 1px 0.1rem 0 var(--shadowColor);
            // border: thin solid var(--borderColor);
            // border-radius: 0.25rem;
            background: var(--background2);

            margin: 1rem 0;
        }

        *[class*="mantine-Menu-root"] {
            display: block;
            width: 100%;
            height: 100%;
        }
    }

    .object {
        display: block;
        border: none;
        border-radius: 0.25rem;
        // background: rgba(255, 255, 255, 0.9);
        background: #228be6;
        cursor: grab;
        padding: 0;
        margin: 0;
        line-height: 1;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 10;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: bold;
        color: white;

        &:focus, &:focus-within, &.selected {
            border: 0.2rem solid var(--invert1);
        }
    }

    .faded {
        opacity: 0.5;
        z-index: 0;
    }

    .timeHandle {
        $color: var(--invert1);
        width: 0;
        height: calc(100% - 2.9rem);
        position: absolute;
        left: 100px;
        bottom: 0rem;
        border-right: thin solid $color;
        border-left: thin solid $color;
        z-index: 100;
        user-select: none;
        cursor: grab;

        svg {
            position: absolute;
            fill: $color;
            // bottom: calc(100% - 2px);
            top: 0;
            left: -6px;
        }
        
        &[data-dragging="true"] {
            opacity: 0.8;
        }
    }

    .timestamps {
        display: flex;
        // margin-bottom: 1rem;
        overflow: hidden;
        border-bottom:thin solid var(--borderColor);

        .timestamp {
            display: block;
            flex-shrink: 0;
            flex-grow: 0;
            position: relative;
            padding: 0.3rem 0.25rem 0.7rem 0.25rem;
            font-size: 0.7rem;
            font-family: "Open Sans", sans-serif;

            &::before, &::after {
                content: " ";
                position: absolute;
                top: 0;
                width: 0;
                height: 75%;
                overflow: hidden;
                border-right: thin solid var(--borderColor);
            }

            &::before {
                left: 0;
            }

            &::after {
                left: 50%;
                height: 25%;
            }
        }

        .timestamp:first-of-type {
            &::before {
                display: none;
            }
        }
    }

    .toolbar {
        display: flex;
        justify-content: center;
        align-items: center;
        // height: 2rem;
        padding: 0 1rem;
        background: var(--background1);
        border-bottom: thin solid var(--borderColor);
        line-height: 1;

        justify-content: space-between;

        &>div {
            flex-basis: 0;
            flex-grow: 1;
        }

        .currentTime {
            display: inline-block;
            text-align: center;
            font-family: "Courier New", monospace;
            font-weight: bold;
            padding: 1rem;

            em {
                font-style: normal;
                font-weight: normal;
                opacity: 0.6;
            }

            span + span {
                &::before {
                    content: "/";
                    margin: 0 0.5rem;
                    opacity: 0.8;
                }
            }
        }

        .toolbarButtons {
            display: flex;
            justify-content: flex-end;

            button + button {
                margin-left: 0.5rem;
            }
        }
    }
}