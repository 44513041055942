.container {
    *[class*="mantine-RadioGroup-description"] {
        color: black !important;
        padding-bottom: 0.5rem;
    }

    // *[class*="mantine-Group-root"] {
    // }

    *[role="radiogroup"] {
        border: thin solid var(--border-color);
        padding: 0 !important;
        gap: 0;
    }

    *[class*="mantine-RadioGroup-radioWrapper"] {
        width: 100%;

        &:hover {
            background: rgba(0, 0, 0, 0.02);
        }

        *[disabled] {
            visibility: hidden;
        }
    }

    *[class*="mantine-RadioGroup-radioWrapper"] + *[class*="mantine-RadioGroup-radioWrapper"] {
        border-top: thin solid var(--border-color);
    }

    label {
        padding: 1rem;
        cursor: pointer;
    }

    *[class*="Disabled"] {
        cursor: default !important;
    }

    label, label span {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    label strong {
        text-align: left;
        font-weight: bold;

        span {
            display: block;
            font-weight: normal;
        }
    }

    .buttonBar {
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .progressBar {
        margin: 0.5rem 0;
    }
}