
.sidebar {
    display: flex;
    width: 100%;
    min-height: 100%;
}

.container {
    // padding: 2rem;
    display: flex;
    flex-direction: column;
    height: 100vh;  

    main {
        padding: 0 !important;
    }

    div[class*="mantine-AppShell-body"] {
        min-height: 0;
        flex-grow: 1;
    }
}

.container main {  
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
    grid-template-rows: minmax(0, 1fr) auto;
    gap: 0px 0px;
    grid-auto-flow: row;
    grid-template-areas:
      "Sidebar Player"
      "Timeline Timeline";
    // height: calc(100vh - 4rem);
  }
  
  .timeline { grid-area: Timeline; }
  
  .sidebar { grid-area: Sidebar; }
  
  .player { 
      grid-area: Player; 
      overflow: hidden;
      padding: 1rem;
    }
  


//     .main {
//         width: 100;
//         display: flex;
//         flex-grow: 1;
//         flex-direction: column;
//         // background: red;

//         &>:first-child {
//             flex-grow: 0;
//             flex-shrink: 0;
//             // background: #eee;
//         }

//         .inner {
//             display: flex;
//             flex-grow: 1;
//             width: 100;
//             // background: blue;

//             // &>:first-child {
//                 // flex-grow: 1;
//                 // background: #ccc;
//             // }
    
//             // &>:last-child {
//             //     width: 25%;
//             //     background: #fff;
//             //     flex-grow: 0;
//             //     flex-shrink: 0;
//             // }
//         }
//     }

//     .content {
//         flex-grow: 1;
//         display: flex;
//         flex-direction: column;
//         height: 100vh;
//         max-height: 100vh;
//         // background: blue;
//     }

//     .empty {
//         flex-grow: 1;
//         display: flex;
//         flex-direction: column;
//         height: 100vh;
//         max-height: 100vh;
//         text-align: center;
//         justify-content: center;
//         padding-bottom: 10vh;
//         // background: blue;
//     }
// }


.launchLink {
    display: flex;
    align-items: flex-end;
    line-height: 1;
    
    a {
        text-decoration: underline;
        font-weight: bold;
    }

    svg {
        margin-left: 0.3rem;
        width: 0.9em;
        height: auto;
    }
}

.propertiesSection + .propertiesSection {
    margin-top: 1rem;
}