.container {
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .main {
        min-height: 0;
        flex-grow: 1;
        flex-shrink: 1;
        text-align: center;
        display: flex;

        *[class*="canvas-container"] {
            width: auto !important;
            height: 100% !important;
        }

        canvas {
            width: auto !important;
            height: 100% !important;
            // background: black;
            border-radius: 0.25rem;
            overflow: hidden;
        }
    }

    &[data-ready="false"] canvas {
        background: black;
        margin: auto;
    }

    .toolbar {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 0.8rem;
        gap: 0.5rem;
    }
}