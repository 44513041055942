.container {
    padding: 2rem;
    // flex-grow: 1;
    // overflow: auto;
    // background: white;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        line-height: 1;
        margin-bottom: 1.618rem;
        padding-bottom: 1.618rem;
        border-bottom: 0.2rem solid #014d8f;

        h1 {
            font-size: 1.618rem;
            font-weight: bold;
        }

        button {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        &, * { vertical-align: middle; }
    }

    .list {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -1.618rem;
        margin-left: -1.618rem;

        .project {
            width: calc((100% - 1.618rem * 4) / 4);
            // border: thin solid black;
            margin-left: 1.618rem;
            margin-bottom: 1.618rem;

            a {
                display: flex;
                flex-direction: column;
                justify-content: center;

                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                width: 100%;
                height: auto;
                min-height: 10em;
                margin-bottom: 0.75rem;
                border-radius: 0.25rem;
                border: none;
                display: block;
                overflow: hidden;
                background: var(--intensity-color);
            }

            text-align: center;
            font-weight: 600;
        }
    }

    .footer {
        margin-top: 1.618rem;
    }
}

.sidebar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 1rem;
}

.userButton {
    display: flex;
    flex-wrap: nowrap;
    padding: 1rem;
    height: auto;
    // margin: 0 -0.5rem;
    border-radius: 0.25rem;
    overflow: hidden;
    background: transparent;

    &:hover {
        background: var(--fade-color);
    }

    &>div {
        padding: 0;
        margin: 0;
        width: 100%;
    }

    *[class*="mantine-Button-label"] {
        &, &>* {
            flex-grow: 1;
            width: 100%;
        }
    }

    * {
        min-width: 0;
        max-width: 100%;
        flex-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.sidebarNav {
    width: 100%;
    margin-top: -0.8rem;
    flex-grow: 1;

    a {
        display: block;
        width: 100%;
        padding: 1rem;
        border-radius: 0.25rem;
        margin-bottom: 0.2rem;
        // margin: 0 -0.5rem;
        span {
            color: var(--foreground);
        }


        &:hover {
            background: var(--fade-color);
        }

        &.active, &.active:hover {
            background: var(--active-color) !important;
            font-weight: 500;
        }
    }
}